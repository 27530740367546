import React, { createRef, useEffect, useRef, useState } from "react";
import Header from "../components/header";
import UpcomingListEl from "../components/upcomingListEl";

import "./style/home.css";
import "./style/contact.css";
import "./style/videos.css";
import "./style/quotes.css";
import "./style/biography.css";

import andrea1 from "../assets/img/andrea1.webp";
import andrea2 from "../assets/img/andrea2.webp";
import Button from "../components/button";
import { getScrollPosition } from "../utils/elementPositionUtils";
import Section from "../components/section";
import PastElement from "../components/pastElement";
import ImageSquare from "../components/imageSquare";
import QuoteSection from "../components/quoteSection";
import NearestUpcomingBanner from "../components/nearestUpcominBanner";
import { BiographyContent, loadBiographySections } from "../content/biographyManager";
import { getImageLink } from "../content/apiManager";
import { loadOnePerformance, loadUpcomingPerformancesAmount, Performance } from "../content/performanceManager";
import { loadVideos, VideoObject } from "../content/videoManager";
import { loadRandomsFromImageGallery } from "../content/galleryManager";
import { getStatic } from "../content/staticManager";
import { formatDate, formatDateMonthYear } from "../utils/formatUtils";
import Loader, { disableLoader } from "../components/loader";
import BiographySection from "../components/biographySection";
import { useLocation } from "react-router-dom";

const HomePage: React.FC = () => {

    let location = useLocation();

    //Data
    const [firstExecute, setFirstExecute] = useState(false);
    const [biography, setBiography] = useState<BiographyContent[]>();
    const [upcomingPerformances, setUpcomingPerformances] = useState<Performance[]>();
    const [videos, setVideos] = useState<VideoObject[]>();
    const [randomImages, setRandomImages] = useState<string[]>();

    const [pastPerformances, setPastPerformances] = useState<(Performance | undefined)[]>();

    function loadPastperformances(i: number, output: (Performance | undefined)[]) {
        if(i == 4) {
            setPastPerformances(output);
            return;
        }
        getStatic("past_performance_" + i, (v: number) => {
            loadOnePerformance(v, (p: Performance) => {
                var new_output = output;
                new_output.push(p);
                loadPastperformances(i+1, new_output);
            })
        }, () => {
            var new_output = output;
            new_output.push(undefined);
            loadPastperformances(i+1, new_output);

        })
    }

    useEffect(() => {
        console.log("fa");
        if(firstExecute) return;
        setFirstExecute(true);
        loadBiographySections(setBiography);
        loadUpcomingPerformancesAmount(4, setUpcomingPerformances);
        loadVideos(setVideos);
        loadRandomsFromImageGallery(4, (v: any) => {
            setRandomImages(v);
            disableLoader();
        });
        loadPastperformances(0, []);

    }, [location])

    const [quoteParallax, setQuoteParallax] = useState(50);

    const [welcomeParallax, setWelcomeParallax] = useState(0);
    const [offset, setOffset] = useState(0);

    function updateWelcomeParallax() {
        setWelcomeParallax(window.pageYOffset/3);
        setOffset(window.innerWidth < 1050 ? (window.innerWidth-1050)/-4 : 0);
        setQuoteParallax(-1 * (50-window.pageYOffset/12));
    }

    useEffect(() => {
        updateWelcomeParallax();
        window.addEventListener("scroll", updateWelcomeParallax);
        window.addEventListener("resize", updateWelcomeParallax);
        return () => {
            window.removeEventListener("scroll", updateWelcomeParallax);
            window.removeEventListener("resize", updateWelcomeParallax);
        }
    })

    if(!biography) {
        return <></>;
    }

    return <div id="home">
        <Header/>
        <Section id="welcome" headerPosition={0}>
            <h1>Andrea</h1>
            <div className="welcome-img" style={{
                "backgroundPosition": "center " + (-200+welcomeParallax + offset) + "px"
            }}/>
            <h1>Cicalese</h1>
            <NearestUpcomingBanner/>
        </Section>
        <section className="banner" id="star">
            <h3>Pianist magazine, London</h3>
            <h2 style={{
                "marginLeft": quoteParallax + "px"
            }}>"Clearly a <b>Star</b></h2>
            <h2 style={{
                "marginRight": quoteParallax + "px"
            }}>in the making"</h2>
        </section>
        <Section headerPosition={1}>
            <h1>Biography</h1>
            <div className="biography">
                <BiographySection img={getImageLink(biography[0].image ? biography[0].image : "")} height={600}>
                    <h3>{biography[1].title}</h3>
                    <p>{biography[1].text}</p>
                </BiographySection>
                <BiographySection className="left" img={getImageLink(biography[2].image ? biography[2].image : "")} height={300}>
                        <h3>{biography[2].title}</h3>
                        <p>{biography[2].text}</p>
                </BiographySection>
                <BiographySection img={getImageLink(biography[3].image ? biography[3].image : "")} height={500}>
                        <h3>{biography[3].title}</h3>
                        <p>{biography[3].text}</p>
                </BiographySection>
                <div className="section only-text">
                    <div className="text">
                        <h3>{biography[4].title}</h3>
                        <p>{biography[4].text}</p>
                    </div>
                    <div className="text">
                        <h3>{biography[5].title}</h3>
                        <p>{biography[5].text}</p>
                    </div>
                </div>
            </div>
        </Section>
        <section>
            <h1>Upcoming Performances</h1>
            <h4>I would love to meet you at one of my upcoming concerts!</h4>
            {upcomingPerformances?.map((e, i) => {
                return <UpcomingListEl left={i == 1 ? 80 : i == 3 ? 120: 0} title={e.getTitle()} subtitle={e.getSubtitle()} img={e.image ? getImageLink(e.image) : ""}/>
                
            })}
            <Button name="All upcoming performances" link="upcoming" dark={true}/>
        </section>
        <Section headerPosition={2} id="videos">
            <h1>Videos</h1>
            <div className="videos">
                {videos?.map(e => {
                    return <div className="row">
                        <iframe width="560" height="315" src={e.link} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        <div className="text">
                            <h2>{e.name}</h2>
                            <p>
                            {e.description}
                            </p>
                        </div>
                    </div>;
                })}
            </div>
        </Section>
        <section id="image-gallery">
            <h1>Image Gallery</h1>
            <div className="images">
                {randomImages?.map(e => {
                    return <ImageSquare img={getImageLink(e)}/>
                })}
            </div>
            <Button name="All images" link="images" dark={false}/>
        </section>
        <Section headerPosition={3} id="quotes">
            <QuoteSection/>
        </Section>

        <Section headerPosition={4} id="past">
            <h1>Highlights</h1>
            <div className="elements">
                {pastPerformances?.map((e, i) => {
                    return <PastElement color={i == 0 ? "orange1" : i == 3 ? "blue2" : "blue1"} right={i == 1 || i == 3} img={getImageLink(e?.image ? e.image : "")} name={e?.location + " " + formatDateMonthYear(e?.date ? e.date : new Date())} category={e?.category  + ""} feature={e?.feature} subtitle={e?.composer + ""}/>;    
                })}
            </div>
            <Button name="Performance Archive" link="archive" dark={true}/>
        </Section>
        <Section headerPosition={5} id="contact">
            <h1>Contact</h1>
            <div className="img" style={{
                "backgroundImage": "url(" + andrea2 + ")"
            }}/>
            <div className="info">
                <h3>Management</h3>
                <h2>Artemon Music</h2>
                <a>www.artemon-music.de</a>
                <h2>NATALIA SEMILETOPULO</h2>
                <a>natalia@artemon-music.de</a>
                <a>+49 163 278 32 59</a>
            </div>
        </Section>
    </div>
}

export default HomePage;