import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, ScrollRestoration } from 'react-router-dom';
import HomePage from './pages/home';

import "./style/colors.css";
import "./style/global.css";
import "./style/fonts/raleway.css";
import Footer from './components/footer';
import PerformancesPage from './pages/performances';
import ImageGalleryPage from './pages/images';
import UpcomingPage from './pages/upcoming';
import ArchivePage from './pages/archive';
import ImprintPage from './pages/imprint';
import Loader from './components/loader';

function NavigateHome() {
  useEffect(() => {
    window.open("/home", "_self");
  });
  return <></>;
}

function App() {

  
  return (<>
    <Loader/>
    <BrowserRouter>
    <Routes>
      <Route path="home" element={<><HomePage/><Footer/></>}/>
      <Route path="upcoming" element={<><UpcomingPage/><Footer/></>}/>
      <Route path="archive" element={<><ArchivePage/><Footer/></>}/>
      <Route path="images" element={<><ImageGalleryPage/><Footer/></>}/>
      <Route path="imprint" element={<><ImprintPage/><Footer/></>}/>
      <Route path="*" element={<Navigate to="home"/>}/>
      <Route path="/" element={<><HomePage/><Footer/></>}/>
      
    </Routes>
    </BrowserRouter>
  </>);
}

export default App;
